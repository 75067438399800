<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="generateDrawer(!drawer)"
    >
      <v-icon v-if="valueUpdater">
        mdi-view-headline
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      text
      to="/login"
      @click="logout()"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components
  // import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState('dashboard', ['drawer', 'notifications', 'notificationsNumber']),
      ...mapGetters('dashboard', ['getDrawer']),
      valueUpdater () {
        return this.valueChanger(this.value)
      },
      notificationsUpdater () {
        return this.notifications
      },
    },

    methods: {
      ...mapMutations('dashboard', ['SET_DRAWER']),
      ...mapActions('dashboard', ['generateDrawer']),
      ...mapActions('account', ['logout']),
      valueChanger (val) {
        val = this.drawer
        return val
      },
    },
  }
</script>
